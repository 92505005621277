import { readState } from "@/__main__/app-state.mjs";
import {
  REGION_LIST,
  ROLE_SYMBOL_TO_STR,
  ROLE_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import {
  getCurrentSet,
  PROBUILDS_MATCH_LENGTH,
} from "@/game-tft/constants.mjs";
import StaticTFT from "@/game-tft/static.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const all = ROLE_SYMBOL_TO_STR[ROLE_SYMBOLS.all].key;

export default function useProBuilds({ filters }) {
  const state = useSnapshot(readState);
  const { proMatchIds, matches_v2 } = state.tft;

  const truncatedMatchIds = Array.isArray(proMatchIds)
    ? proMatchIds.slice(0, PROBUILDS_MATCH_LENGTH)
    : [];

  // For the sake of simplicity, it will just return nothing if not
  // all matches exist yet.
  if (
    !Array.isArray(proMatchIds) ||
    truncatedMatchIds.find((id) => {
      return !matches_v2[id];
    })
  ) {
    return { allMatches: [], filteredMatches: [] };
  }

  const matches = truncatedMatchIds.reduce((result, id) => {
    const match = matches_v2[id];
    if (!(match instanceof Error)) result.push(match);
    return result;
  }, []);

  const currentSet = getCurrentSet();
  const champions = state.tft[currentSet]?.champions;
  const set = new Set();
  const results = Object.values(matches).reduce(
    (acc, match) => {
      const matchSet = `set${match.info.tft_set_number}`;
      if (!set.has(match.metadata.match_id) && matchSet === currentSet) {
        set.add(match.metadata.match_id);
        const result = {
          game: {
            createdAt: match.info.game_datetime,
            queueId: match.info.queue_id,
            region: match.metadata.match_id.split("_")[0],
            matchid: match.metadata.match_id,
          },
          match,
        };
        acc.allMatches.push(result);
        if (
          filterMatches({
            match,
            champions,
            filters,
            set: currentSet,
          })
        ) {
          acc.filteredMatches.push(result);
        }
      }
      return acc;
    },
    {
      allMatches: [],
      filteredMatches: [],
    },
  );
  set.clear();
  return results;
}

function filterMatches({ match, champions, filters, set }) {
  const isChampion = getChampion(filters.champion, {
    match,
    champions,
  });
  const isChampions = getChampions(filters.champions, { match });
  const isTraits = getTraits(filters.traits, { match, set });
  const isRegion = getRegion(filters.region, {
    match,
  });
  const isClass = getClassOrOrigin(filters.classes, { match });
  const isOrigin = getClassOrOrigin(filters.origins, { match });
  const isTopFour = getTopFour(filters.top4Only, match);
  const isAugments = getAugments(filters.augments, { match });
  const isItems = getItems(filters.items, { match });
  return [
    isChampion,
    isChampions,
    isTraits,
    isRegion,
    isClass,
    isOrigin,
    isTopFour,
    isAugments,
    isItems,
  ].every((i) => i);
}

function getChampion(champion, { match }) {
  if (typeof champion === "undefined" || champion === all) return true;
  const { units } = { units: match.units };
  return !!units?.find((unit) =>
    new RegExp(champion, "i").test(unit.characterId),
  );
}

function getChampions(champions, { match }) {
  if (!Array.isArray(champions) || !champions.length) return true;
  return champions.every((champion) =>
    match.info.participants.find((p) => {
      return p.units.find((u) => {
        return u.character_id === champion;
      });
    }),
  );
}

function getTraits(traits, { match, set }) {
  if (!Array.isArray(traits) || !traits.length) return true;
  return traits.every((trait) =>
    match.info.participants.find((p) => {
      return p.traits.find((t) => {
        return t.name === StaticTFT.getTraitApiKey(trait, set);
      });
    }),
  );
}

function getRegion(region, { match }) {
  return (
    region === REGION_LIST[0].key ||
    match.metadata.match_id.split("_")[0]?.toUpperCase() ===
      region?.toUpperCase()
  );
}

function getClassOrOrigin(target, { match }) {
  const result =
    target === all ||
    match.traits?.find((i) => new RegExp(target, "i").test(i.name));
  return result;
}

function getTopFour(isTop4, match) {
  return (
    /false/i.test(isTop4) ||
    [match.placement].some((i) => typeof i === "number" && i < 5)
  );
}

function getAugments(augments, { match }) {
  if (!Array.isArray(augments) || !augments.length) return true;
  return augments.every((aug) =>
    match.info.participants.find((p) => {
      return p.augments.includes(aug);
    }),
  );
}

function getItems(items, { match }) {
  if (!Array.isArray(items) || !items.length) return true;
  return items.every((item) =>
    match.info.participants.find((p) => {
      return p.units.find((unit) => {
        return unit.itemNames.includes(item);
      });
    }),
  );
}
